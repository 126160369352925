import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Components
import Image from 'components/common/Image';

const Logo = ({ type, closeBurgerMenu, isLightTheme }) => {
	const router = useRouter();

	const handleLogoClick = useCallback(() => {
		closeBurgerMenu();

		if (router.pathname !== '/') {
			router.push('/');
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [router.pathname]);

	const logoSrc = useMemo(() => {
		if ((isLightTheme && type === 'burger') || (!isLightTheme && type !== 'burger')) {
			return '/logo/logoLight.svg';
		} else {
			return '/logo/logoDark.svg';
		}
	}, [type, isLightTheme]);

	return (
		<Image
			priority
			alt='Logo'
			src={logoSrc}
			objectFit={'contain'}
			onClick={handleLogoClick}
			className={`logoInternalWrap linkWrap`}
		/>
	);
};

export default withUIContext(Logo, ['closeBurgerMenu', 'isLightTheme']);
