import { memo as Memo, useMemo, useState, useCallback, useEffect } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Helpers
import { gsap } from 'gsap'
import { is_touch_device } from 'helpers'

//* Components
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'

//* Style
import CursorStyle from './style'

const Cursor = Memo(({ winWidth, isLightTheme }) => {
    //! States
    const [state, setState] = useState({
        active: '',
        scaleToClick: '',
        scroll: false,
        drag: false,
    })

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseover', onMouseMove)
        document.addEventListener('mouseleave', onMouseLeave)
        document.addEventListener('mousedown', onMouseDown)
        window.addEventListener('scroll', scroll, false)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseover', onMouseMove)
            document.removeEventListener('mouseout', onMouseLeave)
            document.removeEventListener('mousedown', onMouseDown)
            window.removeEventListener('scroll', scroll, false)
        }
    }, [])

    const scroll = useCallback(() => {
        if (!state.scroll) {
            setState({ ...state, scroll: true })
            onMouseLeave()
        }
    }, [])

    const onMouseMove = useCallback(
        (e) => {
            const el = e.target,
                x = e.clientX,
                y = e.clientY

            let newState = { ...state },
                active = '',
                drag = false

            if (checkActiveWithClass(el, 'linkWrap')) {
                active = 'linkWrap'
            } else if (checkActiveWithClass(el, 'dragIntWrap')) {
                active = 'dragIntWrap'
                drag = true
            } else if (checkActiveWithClass(el, 'videoPlayIconAnim')) {
                active = 'videoPlayIconAnim'
            } else if (checkActiveWithClass(el, 'videoPauseIconAnim')) {
                active = 'videoPauseIconAnim'
            } else if (checkActiveWithClass(el, 'crHide')) {
                active = 'crHide'
            } else {
                active = 'default'
            }

            gsap.set('.cursor', { left: x, top: y })

            active !== state.active && setState({ ...newState, active, drag })
        },
        [state]
    )

    //! Checking element has animation class or not
    const checkActiveWithClass = useCallback((el, cl) => (el.classList.contains(cl) || !!el.closest(`.${cl}`)) && state.active !== cl, [])

    //! Mouse Leave
    const onMouseLeave = useCallback(() => {
        setState({ ...state, active: '' })
    }, [state])

    //! Mouse Down
    const onMouseDown = useCallback(
        (e) => {
            const el = e.target

            let newState = { ...state },
                scaleToClick = '',
                drag = false

            if (checkActiveWithClass(el, 'linkWrap')) {
                scaleToClick = 'cursorClickAnim'
            } else if (checkActiveWithClass(el, 'videoPlayIconAnim')) {
                scaleToClick = 'videoCursorClickAnim'
                newState.active = 'videoPauseIconAnim'
            } else if (checkActiveWithClass(el, 'videoPauseIconAnim')) {
                scaleToClick = 'videoCursorClickReverseAnim'
                newState.active = 'videoPlayIconAnim'
            } else if (checkActiveWithClass(el, 'dragIntWrap')) {
                scaleToClick = 'dragIntWrap'
                drag = true
            } else {
                scaleToClick = 'default mixed'
            }

            setState({ ...newState, scaleToClick, drag })
        },
        [state, isLightTheme],
    )

    const cursorStyle = useMemo(() => {
        return (
            <Icon
                className={`cursor ${isLightTheme ? 'light' : 'dark'} 
                ${state.active} 
                ${state.scaleToClick} 
                ${state.active === 'default' ||
                        state.active === 'linkWrap' ||
                        state.scaleToClick === 'cursorClickAnim' ||
                        state.active === 'videoPlayIconAnim' ||
                        state.active === 'videoPauseIconAnim' ||
                        state.active === 'sliderReverseArrow' ||
                        state.active === 'sliderArrow'
                        ? 'mixed' : 'mixedNone'
                    }
                ${state.active === 'videoPlayIconAnim' && winWidth >= 1024 ? 'bb-play' : ''}
                ${state.active === 'videoPauseIconAnim' && winWidth >= 1024 ? 'bb-pause' : ''}
                ${state.active === 'crHide' && winWidth >= 1024 ? 'crHide' : ''}
        `}
            >
                <div className={`cursorTextWrap`}>
                    {winWidth >= 1024 && state.drag ? <Text className={`p gilroyMedium cursorText  ${isLightTheme ? 'white' : 'blackRussian'}`} text={`drag`} /> : ''}
                </div>
            </Icon>
        )
    }, [state, winWidth])

    return !is_touch_device() && <CursorStyle>{cursorStyle}</CursorStyle>
})

export default withUIContext(Cursor, ['winWidth', 'isLightTheme'])
