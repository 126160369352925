import { createGlobalStyle, css } from 'styled-components';

const Typography = createGlobalStyle`${css`
	* {
		box-sizing: border-box;
		outline: none;
		margin: 0;
		padding: 0;
		-webkit-appearance: none;
		-ms-text-size-adjust: 100%;
		-ms-overflow-style: scrollbar;
		-webkit-text-size-adjust: 100%;
		-webkit-overflow-scrolling: touch;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

		@media (hover: hover) and (pointer: fine) {
			cursor: none !important;
		}
	}

	body {
		position: relative;
		width: 100%;
		min-height: 100vh;
		color: var(--blackRussian);
		background-color: var(--gray99);
		overflow-x: hidden;
		overflow-y: ${(props) => (props.isBurgerMenuOpen ? 'hidden' : '')};
		${(props) =>
			props.isBurgerMenuOpen
				? ` 
                    *{
                        touch-action: none;
                        -ms-touch-action: none;
                    }
                `
				: ``}

		main {
			position: relative;
			min-height: 100vh;
			z-index: 1;
		}

		#__next {
			min-height: 100vh;
			overflow-x: hidden;
		}

		/* &::-webkit-scrollbar {
            display: none;
        } */

		&.hidden {
			overflow: hidden;
			max-height: 100vh;
		}
	}

	ul,
	fieldset {
		list-style: none;
		border: none;
	}

	button {
		border-width: 0;
		border-color: inherit;
		background-color: inherit;
	}

	textarea {
		resize: none;
		/* scrollbar-width: none; 
		-ms-overflow-style: none; 

		&::-webkit-scrollbar {
			display: none;
		} */
	}

	a {
		text-decoration: none;
		color: var(--blackRussian);
	}

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:focus::placeholder {
		color: transparent;
	}

	input {
		outline: none;
	}

	//! Change the transparent to any color
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	textarea:-webkit-autofill:active,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus,
	select:-webkit-autofill:active,
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	input:-internal-autofill-selected {
		color: var(--blackRussian);
		border-bottom: 1px solid var(--blackRussian);
		background-color: transparent;
		transition: background-color 5000s ease-in-out 0s;
		box-shadow: inset 1000px 1000px var(--gray99);
		-webkit-text-fill-color: var(--blackRussian);
	}

	@keyframes autofill {
		to {
			background-color: transparent;
		}
	}

	//! Global Error
	.form-block-wrapper {
		position: relative;

		.error-text {
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	//! Font Sizes
	.h1,
	h1 {
		font-size: var(--h1);
		line-height: var(--lineHeightS);
	}

	.h2,
	h2 {
		font-size: var(--h2);
		line-height: var(--lineHeightS);
	}

	.h3,
	h3 {
		font-size: var(--h3);
		line-height: var(--lineHeightS);
	}

	.h4,
	h4 {
		font-size: var(--h4);
		line-height: var(--lineHeightL);
	}

	.h5,
	h5 {
		font-size: var(--h5);
		line-height: var(--lineHeightL);
	}

	.h6,
	h6 {
		font-size: var(--h6);
		line-height: var(--lineHeightL);
	}

	.p,
	p {
		font-size: var(--p);
		line-height: var(--lineHeightL);
	}

	.pS {
		font-size: var(--pS);
		line-height: var(--lineHeightL);
	}

	//! Buttons Sizes
	.smallBtnTextSize {
		font-size: var(--h4);
	}

	.largeBtnTextSize {
		font-size: var(--h2);
	}

	//! Contact page footer style
	.reverseBackgroundColor {
		background-color: var(--gray99) !important;
		margin-top: 0 !important;

		.container {
			.firstSection {
				.firstColumn {
					.footerEmailWrap,
					.footerPhoneWrap,
					.footerHrEmailWrap {
						a {
							color: var(--blackRussian) !important;
						}
					}

					.footerSocialIconsWrap {
						.iconWrap {
							a {
								i {
									color: var(--blackRussian) !important;
								}
							}
						}
					}
				}

				.secondColumn {
					.footerItemsLeft,
					.footerItemsRight {
						a {
							color: var(--blackRussian) !important;
						}
					}
				}
			}

			.lastSection {
				.firstPart {
					.comp-title {
						color: var(--blackRussian) !important;
					}
				}

				.secondPart {
					.comp-title,
					a {
						color: var(--blackRussian) !important;
					}
				}
			}

			//! Line colors
			.btnLineAnim {
				&:before,
				&:after {
					background-color: var(--blackRussian);
				}
			}
		}
	}

	/* IPad */

	/* Portrait and Landscape */
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) and (max-device-width: ${(props) => props.theme.mediaQuery.TabletSize}) and (-webkit-min-device-pixel-ratio: 1) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

	/* Portrait and Landscape */
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) and (max-device-width: ${(props) => props.theme.mediaQuery.TabletSize}) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* ----------- iPad Pro 10.5" ----------- */

	/* Portrait and Landscape */
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.ipadRotateSmallSize}) and (max-device-width: ${(props) => props.theme.mediaQuery.ipadRotateNormalSize}) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* Portrait */
	/* Declare the same value for min- and max-width to avoid colliding with desktops */
	/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.ipadRotateSmallSize}) and (max-device-width: ${(props) => props.theme.mediaQuery.ipadRotateSmallSize}) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* Landscape */
	/* Declare the same value for min- and max-width to avoid colliding with desktops */
	/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.ipadRotateNormalSize}) and (max-device-width: ${(props) => props.theme.mediaQuery.ipadRotateNormalSize}) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* ----------- iPad Pro 12.9" ----------- */

	/* Portrait and Landscape */
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.TabletSize}) and (max-device-width: ${(props) => props.theme.mediaQuery.ipadRotateLargeSize}) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* Portrait */
	/* Declare the same value for min- and max-width to avoid colliding with desktops */
	/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.TabletSize}) and (max-device-width: ${(props) => props.theme.mediaQuery.TabletSize}) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}

	/* Landscape */
	/* Declare the same value for min- and max-width to avoid colliding with desktops */
	/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
	@media only screen and (min-device-width: ${(props) => props.theme.mediaQuery.ipadRotateLargeSize}) and (max-device-width: ${(props) => props.theme.mediaQuery.ipadRotateLargeSize}) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100% !important;
					animation: unset !important;
				}

				&:after {
					animation: unset !important;
				}
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1) !important;
			}
		}

		//! About us page, Arrow scale animation
		.expertiseContentWrap {
			.rightPart {
				.rightInternalPart {
					.serviceWrap {
						a {
							&:hover {
								i {
									transform: scale(0) !important;
									opacity: 0 !important;
								}
							}
						}
					}
				}
			}
		}

		//! Awards page, Arrow scale and 'award' text slide animations
		.ourAwardsTableWrap {
			.table-row {
				&:hover {
					i {
						opacity: 0 !important;
						transform: scale(0) !important;
					}

					.internalTableRow {
						.rowContentWrap {
							.slideAnim {
								.award {
									animation: unset !important;
								}

								.doubleAward {
									animation: unset !important;
								}
							}
						}
					}
				}
			}
		}
	}
`}`;

export default Typography;
