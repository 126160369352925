export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
			autoload: true,
		},

		work: {
			path: '/works',
			slug: 'projects-page',
			name: 'works',
			menu: true,
			autoload: true,
			postType: {
				slug: 'projects',
				name: 'projects',
				queryParamKeys: ['service_id'],
			},
		},

		singleProject: {
			path: '/works/:slug',
			slug: 'projects',
			name: 'projects',
			singleType: 'singleProject',
			autoload: true,
		},

		aboutUs: {
			path: '/about-us',
			slug: 'about-us',
			name: 'aboutUs',
			menu: true,
			autoload: true,
		},

		services: {
			path: '/services',
			slug: 'services',
			name: 'services',
			menu: true,
			autoload: true,
		},

		singleService: {
			path: '/services/:slug',
			slug: 'services',
			name: 'services',
			singleType: 'singleService',
			autoload: true,
		},

		awards: {
			path: '/awards',
			slug: 'awards',
			name: 'awards',
			menu: true,
			autoload: true,
		},

		publications: {
			path: '/publications',
			slug: 'publications',
			name: 'press',
			menu: true,
			autoload: true,
		},

		contact: {
			path: '/contact',
			slug: 'contact-us',
			name: 'contact',
			menu: true,
			autoload: true,
		},
	},
}
