export const routerToPathAndQuery = (router, key, val, multiple, clearKeyItems) => {
    let newVal = val?.toString()

    if (multiple) {
        let paramsArr = getParamsByKey(router, key)
        if (paramsArr) {
            paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal]
            newVal = paramsArr.join(',')
        }
    }

    const queryObj = { ...router.query, [key]: newVal }
    delete queryObj['params']

    if (clearKeyItems) {
        delete queryObj[clearKeyItems]
    }

    if (router.query[key] === newVal || !newVal) {
        delete queryObj[key]
    }

    const paramsPath = router.query.params && router.query.params.join('/')
    let pathname = router.pathname
    pathname = pathname.replace('[[...params]]', paramsPath || '')

    return { pathname, query: queryObj }
}

export const getParamsByKey = (router, key) => {
    return router.query[key]?.split(',')
}

export const stringToLetters = (text) => {
    let wordLength = text.split(' ')

    return (
        wordLength.map((i, k) => {
            return (
                <span className='word' key={k}>
                    {i}
                </span>
            )
        })
    )
}

export function is_touch_device() {
    try {
        if (typeof window !== undefined) {
            let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')

            let mq = function (query) {
                return window.matchMedia(query).matches
            }

            if ('ontouchstart' in window || (typeof window.DocumentTouch !== 'undefined' && document instanceof window.DocumentTouch)) {
                return true
            }

            return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''))
        }
    } catch (e) {
        return false
    }
}

export const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|jfif)$/.test(url)
}

export const changeCommaToSlash = (text) => {
    const splitText = text.split(',')
    return splitText.map((t, k) => (k !== splitText.length - 1 ? `${t} /` : `${t}`))
}

export const dividedLines = (wordRefsArray, container) => {
    let sum = 0
    let row = []
    let rowCount = 0
    const rowItems = {}

    wordRefsArray.map((word) => {
        sum += word.clientWidth

        if (sum < container.offsetWidth) {
            row.push(word)
        } else {
            sum = 0
            rowCount += 1
            row = [word]
        }

        rowItems[rowCount] = [...row]
    })

    return rowItems
}
