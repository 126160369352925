import { forwardRef } from 'react'
import { LanguageConsumer } from '../providers/LanguageProvider'
import { getContext } from 'helpers'

export default function withLanguageContext(ConnectedComponent, properties = []) {
	return forwardRef((props, ref) => {
		return (
			<LanguageConsumer>
				{(context) => {
					const contextProps = properties.length ? getContext(context, properties) : {}
					return <ConnectedComponent {...contextProps} {...props} ref={ref} />
				}}
			</LanguageConsumer>
		)
	})
}
