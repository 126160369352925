import { Component, createContext } from 'react';
import { withRouter } from 'next/router';
import withLanguageContext from '../consumerHOC/LanguageConsumer';

import { getData, api } from 'helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

class DataProvider extends Component {
	state = { ...this.props.newData };

	methods = {
		contact: this.contact.bind(this),
	};

	contact(data) {
		return api.post('contact-us', data);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const lang = this.props.router.locale || this.props.selectedLang;

		if (prevProps.router.asPath !== this.props.router.asPath || prevProps.router.locale !== this.props.router.locale) {
			getData({ lang, state: this.state, router: this.props.router }).then((res) => {
				this.setState({ ...res, loading: false });
			});
		}
	}

	render() {
		return <DataContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</DataContext.Provider>;
	}
}

export default withRouter(withLanguageContext(DataProvider, ['selectedLang']));
