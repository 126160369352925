const mediaQuery = {
	DesktopSizeXL: '2560px',
	DesktopSizeL: '1920px',
	DesktopSizeM: '1510px',
	DesktopSizeS: '1440px',
	DesktopSizeXS: '1280px',
	TabletSize: '1024px',
	TabletSizeS: '768px',
	MobileSize: '601px',
	MobileSizeS: '414px',
	MobileSizeSMin: '320px',

	DesktopSizeXLMin: '2559px',
	DesktopSizeLMin: '1919px',
	DesktopSizeMMin: '1509px',
	DesktopSizeSMin: '1439px',
	DesktopSizeXSMin: '1279px',
	TabletSizeMin: '1023px',
	TabletSizeSMin: '767px',
	MobileSizeMin: '600px',

	//! Ipad
	ipadRotateLargeSize: '1366px',
	ipadRotateNormalSize: '1112px',
	ipadRotateSmallSize: '834px',
};

const darkColors = {
	blackRussian: '#ffffff',
	white: '#000000',
	gray99: '#000',
	gray44: '#ffffff',
	chineseSilver: '#c6ccd1',
	lineColor: '#e2e2e2',
	footerBgColor: '#000',
	footerTextColor: '#ffffff',
	headerTextColor: '#000000',
	burgerMenuColor: '#000000',
	errorColor: '#FF4950',
	successColor: '#28A94B',
};

const lightColors = {
	blackRussian: '#06050A',
	white: '#ffffff',
	gray99: '#fcfcfc',
	gray44: '#707070',
	chineseSilver: '#c6ccd1',
	lineColor: '#e2e2e2',
	footerBgColor: '#000',
	footerTextColor: '#ffffff',
	headerTextColor: '#ffffff',
	burgerMenuColor: '#ffffff',
	errorColor: '#FF4950',
	successColor: '#28A94B',
};

export default (isLight) => ({
	mediaQuery,
	colors: isLight ? lightColors : darkColors,
});
