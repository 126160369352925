import { forwardRef, useRef, useImperativeHandle, useState } from 'react';
import Image from 'next/image';

//* Style
import ImageStyle from './style';

const CustomImage = forwardRef(({ src, alt, objectFit, priority = false, className, onLoad, onClick }, ref) => {
	//! Ref
	const imgRef = useRef();

	//! State
	const [loaded, setLoaded] = useState(false);

	useImperativeHandle(ref, () => imgRef.current, []);

	//! Handle Image Load
	const onLoadCallback = () => {
		setLoaded(true);

		typeof onLoad === 'function' && onLoad(loaded);
	};

	return (
		<ImageStyle
			ref={imgRef}
			className={`imageCont ${className || ''}`}
			style={{ backgroundColor: loaded ? 'transparent' : '#ECEDEE' }}>
			<span>
				<Image
					layout='fill'
					className='image'
					onClick={onClick}
					draggable={false}
					placeholder='blur'
					priority={priority}
					alt={alt || 'image'}
					objectFit={objectFit}
					sizes='(max-width: 767px) 100%'
					onLoadingComplete={onLoadCallback}
					src={src || '/images/default.png'}
					style={{ opacity: loaded ? 1 : 0 }}
					loading={priority ? 'eager' : 'lazy'}
					blurDataURL={src || '/images/default.png'}
				/>
			</span>
		</ImageStyle>
	);
});

CustomImage.displayName = 'CustomImage';

export default CustomImage;
