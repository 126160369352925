import styled from 'styled-components';

const Footer = styled.footer`
	--footerPadTop: var(--sp26x);
	--footerPadBottom: var(--sp6x);
	--footerSocialIconSize: var(--sp9x);
	--footerSocialIconDistance: var(--sp15x);
	--firstColumnWidth: 47.3%;
	--secondColumnWidth: 31%;
	--marginSize: var(--sp10x);
	--footerMenuItemDistance: var(--sp7x);
	--phoneMTop: var(--sp7x);
	--footerSocialIconsWrapMTop: var(--sp20x);
	--footerLastSecMT: var(--sp26x);

	background-color: var(--footerBgColor);
	padding-top: var(--footerPadTop);
	padding-bottom: var(--footerPadBottom);
	margin-top: var(--secToTextSecToFooterDistance);

	.container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.firstSection {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			position: relative;

			.fakeBackground {
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: var(--blackRussian);
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 111;

				&.whiteBack {
					background-color: var(--gray99);
				}
			}

			.firstColumn {
				width: var(--firstColumnWidth);
				height: fit-content;
				margin-inline-end: var(--marginSize);

				.footerPhoneWrap,
				.footerHrEmailWrap {
					margin-top: var(--phoneMTop);
					direction: ltr;
					display: inline-block;
				}

				.footerSocialIconsWrap {
					display: flex;
					margin-top: var(--footerSocialIconsWrapMTop);

					.iconWrap {
						overflow: hidden;

						&:not(:first-child) {
							margin-inline-start: var(--footerSocialIconDistance);
						}
					}

					i {
						color: var(--footerTextColor);
						font-size: var(--footerSocialIconSize);
					}
				}
			}

			.secondColumn {
				width: var(--secondColumnWidth);
				display: flex;
				justify-content: space-between;
				height: fit-content;

				.footerItemsLeft,
				.footerItemsRight {
					display: inline-flex;
					flex-direction: column;
					width: 50%;
					margin-inline-end: var(--marginSize);

					a {
						width: fit-content;
					}

					.footerMenuItem {
						&:not(:first-child) {
							margin-top: var(--footerMenuItemDistance);
						}
					}
				}
			}
		}

		.lastSection {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			margin-top: var(--footerLastSecMT);

			.secondPart {
				display: flex;
				align-items: center;
				direction: ltr;
			}
		}
	}

	.opacity-0-5 {
		opacity: 0.5;
	}

	//! Line styles and animations
	.btnLineAnim {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 90%;
			height: 2px;
			width: 100%;
			right: 0;
			background-color: var(--footerTextColor);
		}

		&:after {
			content: '';
			position: absolute;
			top: 90%;
			left: 0;
			height: 2px;
			background-color: var(--footerTextColor);
		}

		&:after,
		&:before {
			padding-bottom: 1px;
		}

		&:hover {
			&:before {
				width: 0%;
				animation: lineAnim var(--trTimeS) ease-out reverse;
			}

			&:after {
				animation: lineAnim var(--trTimeS) var(--trTimeN) ease-out forwards;
			}
		}

		@keyframes lineAnim {
			0% {
				width: 0%;
			}

			100% {
				width: 100%;
			}
		}
	}

	//! Scale animations
	.iconWrap {
		transition: var(--trTimeS);

		&:hover {
			transform: scale(1.2);
		}
	}

	.footerMenuItem {
		&:before,
		&:after {
			top: 80%;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--footerPadTop: var(--sp20x);
		--footerPadBottom: var(--sp5x);
		--footerSocialIconSize: var(--sp7x);
		--footerSocialIconDistance: var(--sp11x);
		--firstColumnWidth: 47.3%;
		--secondColumnWidth: 31%;
		--marginSize: var(--sp5x);
		--footerMenuItemDistance: var(--sp5x);
		--phoneMTop: var(--sp6x);
		--footerSocialIconsWrapMTop: var(--sp15x);
		--footerLastSecMT: var(--sp25x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--footerPadTop: var(--sp15x);
		--footerPadBottom: var(--sp4x);
		--footerSocialIconSize: var(--sp5x);
		--footerSocialIconDistance: var(--sp9x);
		--firstColumnWidth: 47.3%;
		--secondColumnWidth: 30%;
		--marginSize: var(--sp2x);
		--footerMenuItemDistance: var(--sp5x);
		--phoneMTop: var(--sp5x);
		--footerSocialIconsWrapMTop: var(--sp11x);
		--footerLastSecMT: var(--sp15x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--footerPadTop: var(--sp15x);
		--footerPadBottom: var(--sp4x);
		--footerSocialIconSize: var(--sp5x);
		--footerSocialIconDistance: var(--sp9x);
		--firstColumnWidth: 47.3%;
		--secondColumnWidth: 30%;
		--marginSize: var(--sp1x);
		--footerMenuItemDistance: var(--sp5x);
		--phoneMTop: var(--sp5x);
		--footerSocialIconsWrapMTop: var(--sp11x);
		--footerLastSecMT: var(--sp15x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--footerPadTop: var(--sp13x);
		--footerPadBottom: var(--sp4x);
		--footerSocialIconSize: var(--sp5x);
		--footerSocialIconDistance: var(--sp8x);
		--firstColumnWidth: 47.3%;
		--secondColumnWidth: 33%;
		--marginSize: var(--sp7x);
		--footerMenuItemDistance: var(--sp6x);
		--phoneMTop: var(--sp5x);
		--footerSocialIconsWrapMTop: var(--sp9x);
		--footerLastSecMT: var(--sp13x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--footerPadTop: var(--sp9x);
		--footerPadBottom: var(--sp4x);
		--footerSocialIconSize: var(--sp4x);
		--footerSocialIconDistance: var(--sp6x);
		--firstColumnWidth: 47.3%;
		--secondColumnWidth: 33%;
		--marginSize: var(--sp3x);
		--footerMenuItemDistance: var(--sp5x);
		--phoneMTop: var(--sp4x);
		--footerSocialIconsWrapMTop: var(--sp7x);
		--footerLastSecMT: var(--sp9x);

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			//! Disable Line styles and animations
			.btnLineAnim {
				&:hover {
					&:before {
						width: 100%;
						animation: unset;
					}

					&:after {
						animation: unset;
					}
				}

				&:after,
				&:before {
					padding-bottom: 0;
				}
			}

			//! Disable scale animations
			.iconWrap {
				&:hover {
					transform: scale(1);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--footerPadTop: var(--sp9x);
		--footerPadBottom: var(--sp4x);
		--footerSocialIconSize: var(--sp4x);
		--footerSocialIconDistance: var(--sp6x);
		--firstColumnWidth: 100%;
		--secondColumnWidth: 53%;
		--marginSize: var(--sp4x);
		--footerMenuItemDistance: var(--sp5x);
		--phoneMTop: var(--sp4x);
		--footerSocialIconsWrapMTop: var(--sp6x);
		--footerLastSecMT: var(--sp12x);

		.container {
			.firstSection {
				.firstColumn {
					margin-right: 0;
				}

				.secondColumn {
					margin-top: var(--sp9x);
				}
			}
		}

		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--footerPadTop: var(--sp8x);
		--footerPadBottom: var(--sp4x);
		--footerSocialIconSize: var(--sp4x);
		--footerSocialIconDistance: var(--sp4x);
		--firstColumnWidth: 100%;
		--secondColumnWidth: 100%;
		--marginSize: var(--sp3x);
		--footerMenuItemDistance: var(--sp5x);
		--phoneMTop: var(--sp4x);
		--footerSocialIconsWrapMTop: var(--sp6x);
		--footerLastSecMT: var(--sp10x);

		.container {
			.firstSection {
				.firstColumn {
					margin-right: 0;

					.footerEmailWrap {
						word-break: break-word;
					}

					.footerSocialIconsWrap {
						justify-content: space-between;

						.iconWrap {
							&:not(:first-child) {
								margin-left: 0;
							}
						}
					}
				}

				.secondColumn {
					margin-top: var(--sp8x);
				}
			}

			.lastSection {
				flex-direction: column;
				align-items: flex-start;

				.secondPart {
					margin-top: var(--marginSize);
				}
			}
		}

		//! Disable Line styles and animations
		.btnLineAnim {
			&:hover {
				&:before {
					width: 100%;
					animation: unset;
				}

				&:after {
					animation: unset;
				}
			}

			&:after,
			&:before {
				padding-bottom: 0;
			}
		}

		//! Disable scale animations
		.iconWrap {
			&:hover {
				transform: scale(1);
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.MobileSizeMin}) {
			.container {
				.firstSection {
					.firstColumn {
						.footerEmailWrap {
							word-break: break-word;
						}

						.footerSocialIconsWrap {
							justify-content: unset;

							.iconWrap {
								&:not(:first-child) {
									margin-left: var(--sp6x);
								}
							}
						}
					}
				}

				.lastSection {
					flex-direction: row;
					align-items: center;

					.secondPart {
						margin-top: 0;
					}
				}
			}
		}
	}
`;

export default Footer;
