import App from 'next/app';
import dynamic from 'next/dynamic';

//* Providers
import { UIProvider, DataProvider, LanguageProvider } from 'context';

//* Components
import AppHeader from 'components/common/AppHeader';
import AppFooter from 'components/common/AppFooter';
import Cursor from 'components/common/Cursor';
const Preloader = dynamic(() => import('components/common/Preloader'), { ssr: true });

//* Helpers
import { getData, getPageParams, config } from 'helpers';

//* Styles
import 'styles/font.css';
import 'swiper/css/bundle';
import 'styles/icons/style.css';
import 'styles/antd.custom.select.css';

class MyApp extends App {
	static async getInitialProps({ Component, ctx, router, ...props }) {
		//! Checking If First Request or Not
		if (!process.browser) {
			//! Default States
			const newState = {
				pages: {},
				global: {},
			};

			//! Getting Page Data
			const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true });

			return {
				pageProps: {
					...data,
					browser: false,
				},
			};
		} else {
			return {
				pageProps: {
					pageParams: getPageParams({ lang: ctx.locale, router: ctx }),
					browser: true,
				},
			};
		}
	}

	render() {
		const { Component, pageProps, router } = this.props;

		return (
			<LanguageProvider>
				<UIProvider>
					<DataProvider newData={pageProps}>
						<Preloader />

						<AppHeader />

						<Component
							{...pageProps}
							config={config.routes}
							selectedLang={router.locale}
						/>

						<AppFooter />

						<Cursor />
					</DataProvider>
				</UIProvider>
			</LanguageProvider>
		);
	}
}

export default MyApp;
