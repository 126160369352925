import { createGlobalStyle, css } from 'styled-components';

const HelperClass = createGlobalStyle`${css`
	//! Text capitalize
	.uppercase {
		text-transform: uppercase;
	}

	.lowercase {
		text-transform: lowercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.underline {
		text-decoration: underline;
	}

	//! Fonts
	.gilroyLight {
		font-family: gilroy;
		font-weight: 300;
	}

	.gilroyRegular {
		font-family: gilroy;
		font-weight: 400;
	}

	.gilroyMedium {
		font-family: gilroy;
		font-weight: 600;
	}

	.gilroySemiBold {
		font-family: gilroy;
		font-weight: 700;
	}

	.maisonNeueLight {
		font-family: maisonNeue;
		font-weight: 300;
	}

	.maisonNeueBook {
		font-family: maisonNeue;
		font-weight: 500;
	}

	.maisonNeueMedium {
		font-family: maisonNeue;
		font-weight: 600;
	}

	//! Colors
	.blackRussian {
		color: var(--blackRussian);
	}

	.white {
		color: var(--white);
	}

	.gray99 {
		color: var(--gray99);
	}

	.gray44 {
		color: var(--gray44);
	}

	.chineseSilver {
		color: var(--chineseSilver);
	}

	.lineColor {
		color: var(--lineColor);
	}

	.footerTextColor {
		color: var(--footerTextColor);
	}

	.headerTextColor {
		color: var(--headerTextColor);
	}

	.errorColor {
		color: var(--errorColor);
	}

	.successColor {
		color: var(--successColor);
	}

	//! Cursor
	.cursorPointer {
		cursor: pointer;
	}

	.pointerNone {
		pointer-events: none;
	}

	//! Opacity zero
	.opacityZero {
		opacity: 0;
	}

	//! Display
	.displayBlock {
		display: block;
	}

	//! Clip-path
	.circleClipPathDefaultState {
		clip-path: circle(0% at 50% 50%);
	}

	//! Overflow hidden
	.overflowHidden {
		overflow: hidden;
	}

	//! No wrap
	.noWrap {
		white-space: nowrap;
	}

	//! Text center
	.textCenter {
		text-align: center;
	}

	//! For text new animation
	.lines-relative-wrapper {
		position: relative;

		.animated-flex-wrapper {
			display: flex;
			flex-wrap: wrap;

			&.absolute-opacity-wrapper {
				position: absolute;
				inset: 0;
				opacity: 0;
			}
		}

		.flex-wrapper {
			display: flex;
			flex-wrap: wrap;

			.comp-title {
				margin-right: var(--animTitleWordDistance);
			}
		}
	}
`}`;

export default HelperClass;
