import styled from 'styled-components';

const ImageStyle = styled.div`
	--proportion: 50%;

	&.imageCont {
		width: 100%;
		position: relative;
		padding-top: var(--proportion);
		transition: background-color 0.3s ease-out;

		.image {
			object-fit: cover;
			object-position: center;
			transition: opacity 0.6s ease-out;
			z-index: 1;
		}
	}
`;
export default ImageStyle;
