import { memo as Memo, useMemo, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Helper's
import { scale, opacity } from 'helpers/_gsapFunctions'
import config from 'helpers/_config'

//* Components
import Logo from '../Logo'
import Container from '../../Container'
import Icon from '../../Icon'
import CustomLink from '../../CustomLink'

//* Styles
import BurgerMenuStyle from './style'

const BurgerMenu = Memo(({ isBurgerMenuOpen, toggleBurgerMenu, global, selectedLang, translate }) => {
	//! Global data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

	//! Router
	const router = useRouter()

	//! Refs
	const closeIconRef = useRef()
	const burgerMenuItemsRef = useRef([])
	const emailRef = useRef()
	const hr_emailRef = useRef()
	const phoneRef = useRef()
	const facebookRef = useRef()
	const behanceRef = useRef()
	const instagramRef = useRef()
	const pinterestRef = useRef()
	const linkedinRef = useRef()

	useEffect(() => {
		const arr = [
			...burgerMenuItemsRef.current,
			emailRef.current,
			hr_emailRef.current,
			phoneRef.current,
			facebookRef.current,
			behanceRef.current,
			instagramRef.current,
			pinterestRef.current,
			linkedinRef.current,
		]
		const filterArr = arr.filter((el) => typeof el !== 'undefined')

		//! item, delay, duration
		scale(closeIconRef.current, 0.2, 0.5)

		//! item, stagger, delay, duration
		opacity(filterArr, 0.1, 0.1, 1.2)
	}, [isBurgerMenuOpen])

	//! Burger Menu Items
	const burgerMenuItems = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].menu) {
				return (
					<CustomLink
						url={route[1].path}
						key={ind}
						ref={(el) => (burgerMenuItemsRef.current[ind] = el)}
						className={`uppercase h6 gilroyRegular headerTextColor burgerMenuItem ${router.pathname === route[1].path ? 'active' : ''} `}
						content={translate(route[1].name)}
					/>
				)
			}
		})
	}, [isBurgerMenuOpen])

	return (
		<BurgerMenuStyle className={`burger-menu ${isBurgerMenuOpen ? 'active' : ''}`}>
			<Container>
				<div className={'burgerMenuFirstContentWrap'}>
					<Logo type={'burger'} />
					<Icon ref={closeIconRef} className={'bb-cross cursorPointer closeIconWrap'} onClick={toggleBurgerMenu} />
				</div>

				<div className={'contentGlobWrap'}>
					<div className={'burgerMenuItemsWrap'}>{burgerMenuItems}</div>

					<div className={'lastPartWrap'}>
						<div className={'emailWrap'} ref={emailRef}>
							<CustomLink
								rel={'noopener noreferrer'}
								url={`mailto:${globalData?.contact_details.email}`}
								className={'h3 gilroyMedium underline white uppercase'}
								content={globalData?.contact_details.email}
								ariaLabel={globalData?.contact_details.email}
							/>
						</div>

						<div className={'hrEmailWrap'} ref={hr_emailRef}>
							<CustomLink
								rel={'noopener noreferrer'}
								url={`mailto:${globalData?.contact_details.second_email}`}
								className={'h3 gilroyMedium underline white uppercase'}
								content={globalData?.contact_details.second_email}
								ariaLabel={globalData?.contact_details.second_email}
							/>
						</div>

						<div className={'phoneWrap'} ref={phoneRef}>
							<CustomLink
								rel={'noopener noreferrer'}
								url={`tel:${globalData?.contact_details.phone}`}
								className={'h3 gilroyMedium underline white uppercase'}
								content={`+ ${globalData?.contact_details.phone}`}
								ariaLabel={`+ ${globalData?.contact_details.phone}`}
							/>
						</div>

						<div className='burgerSocialIconsWrap'>
							{globalData?.socials.facebook && (
								<div className={'iconWrap'} ref={facebookRef}>
									<CustomLink
										url={globalData?.socials.facebook}
										options={{
											rel: 'noopener noreferrer',
											target: '_blank',
										}}
										ariaLabel={globalData?.socials.facebook}
									>
										<Icon className='bb-facebook' />
									</CustomLink>
								</div>
							)}
							{globalData?.socials.behance && (
								<div className={'iconWrap'} ref={behanceRef}>
									<CustomLink
										url={globalData?.socials.behance}
										options={{
											rel: 'noopener noreferrer',
											target: '_blank',
										}}
										ariaLabel={globalData?.socials.behance}
									>
										<Icon className='bb-behance' />
									</CustomLink>
								</div>
							)}
							{globalData?.socials.instagram && (
								<div className={'iconWrap'} ref={instagramRef}>
									<CustomLink
										url={globalData?.socials.instagram}
										options={{
											rel: 'noopener noreferrer',
											target: '_blank',
										}}
										ariaLabel={globalData?.socials.instagram}
									>
										<Icon className='bb-instagram' />
									</CustomLink>
								</div>
							)}
							{globalData?.socials.pinterest && (
								<div className={'iconWrap'} ref={pinterestRef}>
									<CustomLink
										url={globalData?.socials.pinterest}
										options={{
											rel: 'noopener noreferrer',
											target: '_blank',
										}}
										ariaLabel={globalData?.socials.pinterest}
									>
										<Icon className='bb-pinterest' />
									</CustomLink>
								</div>
							)}
							{globalData?.socials.linkedin && (
								<div className={'iconWrap'} ref={linkedinRef}>
									<CustomLink
										url={globalData?.socials.linkedin}
										options={{
											rel: 'noopener noreferrer',
											target: '_blank',
										}}
										ariaLabel={globalData?.socials.linkedin}
									>
										<Icon className='bb-linkedin' />
									</CustomLink>
								</div>
							)}
						</div>
					</div>
				</div>
			</Container>
		</BurgerMenuStyle>
	)
})

export default withLanguageContext(withDataContext(withUIContext(BurgerMenu, ['toggleBurgerMenu', 'isBurgerMenuOpen', '']), ['global']), [
	'selectedLang',
	'translate',
])
