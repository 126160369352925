import styled from 'styled-components';

const BurgerMenuStyle = styled.div`
	width: 100vw;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 100%;
	background: var(--blackRussian);
	z-index: 999;
	transition: var(--trTimeN);

	&.active {
		left: 0;
	}

	.container {
		height: 100%;

		.burgerMenuFirstContentWrap {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: var(--sp2x);
			padding-bottom: var(--sp2x);
			border-bottom: 2px solid var(--burgerMenuColor);

			.imageCont {
				--proportion: 2.9%;

				width: 16.8%;

				img {
					object-position: left;
				}
			}

			i {
				font-size: var(--sp3x);

				&:before {
					color: var(--burgerMenuColor);
				}
			}
		}

		.contentGlobWrap {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			padding-bottom: var(--sp17x);
			margin-top: var(--sp4x);

			.burgerMenuItemsWrap {
				display: inline-flex;
				flex-direction: column;
				justify-content: flex-end;

				a {
					width: fit-content;
				}

				.burgerMenuItem {
					&.active {
						font-family: gilroy;
						font-weight: 700;
						pointer-events: none;
					}

					&:not(:first-child) {
						margin-top: var(--sp3x);
					}
				}
			}

			.lastPartWrap {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.phoneWrap,
				.hrEmailWrap {
					margin-top: var(--sp4x);
				}

				.burgerSocialIconsWrap {
					display: flex;
					margin-top: var(--sp5x);

					i {
						font-size: var(--sp4x);

						&:before {
							color: var(--burgerMenuColor);
						}
					}

					.iconWrap {
						&:not(:first-child) {
							margin-left: var(--sp6x);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeMin}) {
		--burgerFirstContentPadT: var(--sp2x);
		--burgerFirstContentPadB: var(--sp3x);
		--logoWidth: 32.5%;
		--logoHeight: 5.5%;

		.container {
			.burgerMenuFirstContentWrap {
				padding-top: var(--sp2x);
				padding-bottom: var(--sp3x);

				.imageCont {
					--proportion: 6.6%;
					width: 32.3%;
				}
			}

			.contentGlobWrap {
				flex-direction: column;
				margin-top: 0;
				padding-bottom: var(--sp17x);

				.burgerMenuItemsWrap {
					margin-top: var(--sp4x);
					justify-content: unset;
				}

				.lastPartWrap {
					display: unset;
					flex-direction: unset;
					justify-content: unset;
					margin-top: var(--sp3x);

					.emailWrap {
						word-wrap: break-word;
					}

					.burgerSocialIconsWrap {
						justify-content: space-between;

						.iconWrap {
							&:not(:first-child) {
								margin-left: 0;
							}
						}
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeS}) {
			.container {
				.contentGlobWrap {
					.burgerMenuItemsWrap {
						margin-bottom: var(--sp2x);
					}
				}
			}
		}
	}

	/* Rotate */
	@media screen and (orientation: landscape) {
		.container {
			.burgerMenuFirstContentWrap {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: var(--sp2x);
				padding-bottom: var(--sp2x);
				border-bottom: 2px solid var(--burgerMenuColor);

				.imageCont {
					--proportion: 2.9%;
					width: 16.8%;
				}

				i {
					font-size: var(--sp3x);

					&:before {
						color: var(--burgerMenuColor);
					}
				}
			}

			.contentGlobWrap {
				flex: 1;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				height: 100%;
				padding-bottom: var(--sp11x);
				margin-top: var(--sp4x);

				.burgerMenuItemsWrap {
					display: inline-flex;
					flex-direction: column;
					justify-content: flex-end;

					a {
						width: fit-content;
					}

					.burgerMenuItem {
						&.active {
							font-family: gilroy;
							font-weight: 700;
							pointer-events: none;
						}

						&:not(:first-child) {
							margin-top: var(--sp3x);
						}

						font-size: var(--sp1-5x);
					}
				}

				.lastPartWrap {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					.phoneWrap,
					.hrEmailWrap {
						margin-top: var(--sp4x);
					}

					.burgerSocialIconsWrap {
						display: flex;
						margin-top: var(--sp5x);

						i {
							font-size: var(--sp4x);

							&:before {
								color: var(--burgerMenuColor);
							}
						}

						.iconWrap {
							&:not(:first-child) {
								margin-left: var(--sp6x);
							}
						}
					}
				}
			}
		}
	}

	@media screen and (orientation: landscape) and (min-width: 780px) {
		.contentGlobWrap {
			margin-top: calc(-1 * var(--sp4x)) !important;
		}
	}
`;

export default BurgerMenuStyle;
