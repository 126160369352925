import { memo as Memo, useEffect } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* GSAP
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const AnimationWrap = Memo(({ state, startAnimeArr, startPoint, dependency, loading, ...props }) => {
	useEffect(() => {
		let genArr = []

		if (!props.preloader && state?.length > 0 && state !== null) {
			state.forEach((el, i) => {
				if (el) {
					ScrollTrigger.create({
						trigger: el,
						start: startPoint || 'top bottom',
						// markers: true,
						ease: 'power2.out',
						onEnter: () => {
							genArr.push(true)

							if (genArr.length <= state.length) {
								startAnimeArr(genArr)
							}
						},
					})
				}
			})
		}
	}, [state, loading, props.preloader, dependency])

	useEffect(() => {
		return () => {
			ScrollTrigger.getAll().forEach((t) => t.kill())
		}
	}, [dependency])

	return <>{props.children}</>
})

export default withUIContext(AnimationWrap, ['preloader'])
