import styled from 'styled-components';

const HeaderStyle = styled.header`
	--headerContentPadT: var(--sp5x);
	--headerContentPadB: var(--sp5x);
	--navBarItemDistance: var(--sp13x);
	--logoWidth: 11.5%;
	--logoHeight: 18%;

	position: fixed;
	width: 100%;
	top: -100%;
	transition: var(--trTimeN);
	z-index: 3;
	background-color: var(--gray99);

	&.visible {
		top: 0;
		transform: translateY(-1px); /** //! To fix the header UI issue during IOS */
	}

	.headerContentWrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: var(--headerContentPadT);
		padding-bottom: var(--headerContentPadB);
		position: relative;

		.fakeBackHeader {
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: var(--gray99);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 111;
		}

		.line {
			position: absolute;
			height: 1px;
			background-color: var(--gray44);
			bottom: 0;
			left: 0;
			width: 100%;
		}

		.logoWrap {
			width: 100%;
			max-width: var(--logoWidth);

			.imageCont {
				--proportion: var(--logoHeight);

				img {
					object-position: left;
				}
			}
		}

		.navBarMenuWrap {
			display: flex;
			align-items: center;

			.navBarMenuItemGlobWrap {
				position: relative;

				&:not(:first-child) {
					margin-inline-start: var(--navBarItemDistance);
				}

				.fakeItem {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					white-space: nowrap;
					opacity: 0;
				}

				&.active {
					pointer-events: none;

					.originalItem {
						opacity: 0;
					}

					.fakeItem {
						opacity: 1;
					}
				}
			}
		}

		i {
			font-size: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--headerContentPadT: var(--sp4x);
		--headerContentPadB: var(--sp4x);
		--navBarItemDistance: var(--sp9x);
		--logoWidth: 11.2%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--headerContentPadT: var(--sp3x);
		--headerContentPadB: var(--sp3x);
		--navBarItemDistance: var(--sp8x);
		--logoWidth: 12.1%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerContentPadT: var(--sp3x);
		--headerContentPadB: var(--sp3x);
		--navBarItemDistance: var(--sp8x);
		--logoWidth: 12.5%;
		--logoHeight: 18.2%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerContentPadT: var(--sp3x);
		--headerContentPadB: var(--sp4x);
		--navBarItemDistance: var(--sp6x);
		--logoWidth: 12.5%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--headerContentPadT: var(--sp3x);
		--headerContentPadB: var(--sp4x);
		--navBarItemDistance: var(--sp5x);
		--logoWidth: 13.8%;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--headerContentPadT: var(--sp3x);
		--headerContentPadB: var(--sp4x);
		--navBarItemDistance: unset;
		--logoWidth: 18.8%;

		.headerContentWrap {
			.navBarMenuWrap {
				display: none;
			}
		}

		@media only screen and (max-width: 896px) {
			--headerContentPadT: var(--sp2x);
			--headerContentPadB: var(--sp2x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--headerContentPadT: var(--sp2x);
		--headerContentPadB: var(--sp3x);
		--navBarItemDistance: unset;
		--logoWidth: 32.3%;

		.headerContentWrap {
			.navBarMenuWrap {
				display: none;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.MobileSizeMin}) {
			--logoWidth: 16.8%;
			--headerContentPadB: var(--sp2x);
		}
	}
`;

export default HeaderStyle;
