import { createGlobalStyle } from 'styled-components';

const RTL = createGlobalStyle`
    html[dir="rtl"] {
        .bubbles-content-container {
            .bubbles-container {
                margin-inline-end: calc(4 * var(--containerPad));
            }
        }
    }
`;

export default RTL;
