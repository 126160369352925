import { memo as Memo, useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { useRouter } from 'next/router';

//* HOC's
import withLanguageContext from 'context/consumerHOC/LanguageConsumer';
import withUIContext from 'context/consumerHOC/UIConsumer';

//* Helper's
import config from 'helpers/_config';
import { opacity, line, fakeBack } from 'helpers/_gsapFunctions';

//* Components
import Container from 'components/common/Container';
import CustomLink from 'components/common/CustomLink';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';
import Logo from './Logo';
import BurgerMenu from './BurgerMenu';

//* Style
import HeaderStyle from './style';

const AppHeader = Memo(({ winWidth, toggleBurgerMenu, closeBurgerMenu, translate, preloader }) => {
	//! Router
	const router = useRouter();

	//! States
	const [y, setY] = useState();
	const [scrollDirection, setScrollDirection] = useState(true);

	//! Refs
	const logoRef = useRef();
	const navBarMenuItemsRef = useRef([]);
	const lineRef = useRef();
	const burgerIconRef = useRef();
	const headerRef = useRef();
	const headerFakeBack = useRef();

	//? Header navigation function
	const handleNavigation = useCallback(
		(e) => {
			const window = e.currentTarget;
			if (y > window.scrollY || y < 0 || window.scrollY < 0) {
				setScrollDirection(true);
			} else {
				setScrollDirection(false);
			}
			setY(window.scrollY);
		},
		[y]
	);

	useEffect(() => {
		if (!preloader) {
			//! Checkers
			const arr = [logoRef.current, burgerIconRef.current, ...navBarMenuItemsRef.current];
			const filterArr = arr.filter((el) => typeof el !== 'undefined');

			//! item, stagger, delay, duration
			opacity(filterArr, 0.08, 0, 0.7);

			//! item, delay, duration
			fakeBack(headerFakeBack.current, 0.1, 0.15);

			//! item, stagger, delay, duration
			line(lineRef.current, 0, 0.4, 0.4);
		}
	}, [preloader]);

	useEffect(() => {
		if (!preloader) {
			closeBurgerMenu();
		}
	}, [preloader, router.asPath]);

	//? Header navigation
	useEffect(() => {
		window.addEventListener('scroll', handleNavigation);

		return () => {
			window.removeEventListener('scroll', handleNavigation);
		};
	}, [handleNavigation]);

	//! Nav Bar
	const navBar = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].menu) {
				return (
					<CustomLink
						className={`navBarMenuItemGlobWrap ${router.pathname === route[1].path ? 'active' : ''}`}
						ref={(el) => (navBarMenuItemsRef.current[ind] = el)}
						url={route[1].path}
						key={ind}>
						<Text
							className={`originalItem uppercase h6 gilroyRegular blackRussian`}
							text={route[1].name}
						/>
						<Text
							className={`fakeItem uppercase h6 gilroySemiBold blackRussian`}
							text={route[1].name}
						/>
					</CustomLink>
				);
			}
		});
	}, [router.pathname]);

	//! Burger Menu
	const burgerMenu = useMemo(() => {
		if (winWidth < 1024) {
			return <BurgerMenu />;
		}
	}, [winWidth]);

	//! Burger Menu Icon
	const burgerMenuIcon = useMemo(() => {
		if (winWidth < 1024) {
			return (
				<div ref={burgerIconRef}>
					<Icon
						onClick={toggleBurgerMenu}
						className='bb-burger cursorPointer'
					/>
				</div>
			);
		}
	}, [winWidth, toggleBurgerMenu]);

	//! Header line
	const headerLine = useMemo(() => {
		return (
			router.asPath !== '/' &&
			router.route !== '/works/[slug]' && (
				<span
					className='line'
					ref={lineRef}
				/>
			)
		);
	}, [router.asPath]);

	return (
		<>
			<HeaderStyle
				className={`header ${scrollDirection ? 'visible' : ''}`}
				ref={headerRef}>
				<Container>
					<div className={'headerContentWrap'}>
						<div
							className={`fakeBackHeader`}
							ref={headerFakeBack}
						/>
						<div
							className={'logoWrap'}
							ref={logoRef}>
							<Logo />
						</div>
						<div className={'navBarMenuWrap'}>{navBar}</div>
						{burgerMenuIcon}
						{headerLine}
					</div>
				</Container>
			</HeaderStyle>
			{burgerMenu}
		</>
	);
});

export default withLanguageContext(withUIContext(AppHeader, ['winWidth', 'toggleBurgerMenu', 'closeBurgerMenu', 'preloader']), ['translate']);
