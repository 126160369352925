import { forwardRef, useId } from 'react'
import { withLanguageContext } from 'context'
import Link from 'next/link'

const CustomLink = forwardRef(({ selectedLang, options, ...props }, ref) => {
	const id = useId()

	return (
		<Link scroll={props.scroll} shallow={props.shallow} href={props.url}>
			<a
				ref={ref}
				id={id}
				rel={props.rel}
				className={`linkWrap ${props.className}`}
				aria-label={props.ariaLabel}
				onMouseOver={props.onMouseOver}
				onMouseOut={props.onMouseOut}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
				onClick={props.onClick}
				{...options}
			>
				{props.content || props.children}
			</a>
		</Link>
	)
})

CustomLink.displayName = 'CustomLink'

export default withLanguageContext(CustomLink, ['selectedLang'])
