import { createGlobalStyle, css } from 'styled-components';

function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 200; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

const Variables = createGlobalStyle`${css`
	:root {
		//! Colors
		--blackRussian: ${(props) => props.theme.colors.blackRussian};
		--white: ${(props) => props.theme.colors.white};
		--gray99: ${(props) => props.theme.colors.gray99};
		--gray44: ${(props) => props.theme.colors.gray44};
		--chineseSilver: ${(props) => props.theme.colors.chineseSilver};
		--lineColor: ${(props) => props.theme.colors.lineColor};
		--footerBgColor: ${(props) => props.theme.colors.footerBgColor};
		--footerTextColor: ${(props) => props.theme.colors.footerTextColor};
		--headerTextColor: ${(props) => props.theme.colors.headerTextColor};
		--burgerMenuColor: ${(props) => props.theme.colors.burgerMenuColor};
		--errorColor: ${(props) => props.theme.colors.errorColor};
		--successColor: ${(props) => props.theme.colors.successColor};

		//! Screen Sizes
		--DesktopSizeXL: ${(props) => props.theme.mediaQuery.DesktopSizeXL};
		--DesktopSizeXLMin: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin};
		--DesktopSizeL: ${(props) => props.theme.mediaQuery.DesktopSizeL};
		--DesktopSizeLMin: ${(props) => props.theme.mediaQuery.DesktopSizeLMin};
		--DesktopSizeM: ${(props) => props.theme.mediaQuery.DesktopSizeM};
		--DesktopSizeMMin: ${(props) => props.theme.mediaQuery.DesktopSizeMMin};
		--DesktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
		--DesktopSizeSMin: ${(props) => props.theme.mediaQuery.DesktopSizeSMin};
		--DesktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
		--DesktopSizeXSMin: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin};
		--TabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
		--TabletSizeMin: ${(props) => props.theme.mediaQuery.TabletSizeMin};
		--TabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
		--TabletSizeSMin: ${(props) => props.theme.mediaQuery.TabletSizeSMin};
		--MobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
		--MobileSizeMin: ${(props) => props.theme.mediaQuery.MobileSizeMin};
		--MobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};
		--MobileSizeSMin: ${(props) => props.theme.mediaQuery.MobileSizeSMin};
		--ipadRotateLargeSize: ${(props) => props.theme.mediaQuery.ipadRotateLargeSize};
		--ipadRotateNormalSize: ${(props) => props.theme.mediaQuery.ipadRotateNormalSize};
		--ipadRotateSmallSize: ${(props) => props.theme.mediaQuery.ipadRotateSmallSize};

		//! Spacing
		--sp0-75x: 6px;
		--spSize: 8px;
		--sp1-5x: 12px;
		--sp2-5x: 20px;
		${spaceGenerator()};

		//! Transition
		--trTimeL: 0.7s;
		--trTimeN: 0.5s;
		--trTimeS: 0.3s;

		//! Line heights
		--lineHeightS: 1.2;
		--lineHeightL: 1.5;
	}

	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
		:root {
			--h1: 160px;
			--h2: 104px;
			--h3: 72px;
			--h4: 56px;
			--h5: 40px;
			--h6: 32px;
			--p: 28px;
			--pS: 18px;

			//! Container padding
			--containerPad: var(--sp12x);

			//! Sections distance
			--sectionsDistance: var(--sp20x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp28x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp9x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp9x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp7x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp7x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp6x);

			//! Title to text
			--titleToTextDistance: var(--sp4x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp10x);

			//! Pic to slider
			--picToSliderDistance: var(--sp5x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp14x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp6x);

			//! Title to down line
			--titleToDownLine: var(--sp5x);

			//! Cursor normal size
			--cursorNormalSize: var(--sp5x);

			//! Cursor text size
			--cursorTextSize: var(--sp19x);

			//! Hover on clickable element
			--cursorHoverOnClickableElemSize: var(--sp9x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp20x);

			//! Slider arrow icon size
			--sliderArrowIconSize: var(--sp10x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp25x);

			//! Drag items distance
			--dragDistance: var(--sp7x);

			//! Related title distance
			--relatedTitleDistance: var(--sp7x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp5x);
			--animTitleWordDistanceSmall: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		:root {
			--h1: 124px;
			--h2: 80px;
			--h3: 56px;
			--h4: 40px;
			--h5: 32px;
			--h6: 24px;
			--p: 20px;
			--pS: 16px;

			//! Container padding
			--containerPad: var(--sp10x);

			//! Sections distance
			--sectionsDistance: var(--sp14x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp20x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp8x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp8x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp5x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp5x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp5x);

			//! Title to text
			--titleToTextDistance: var(--sp3x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp8x);

			//! Pic to slider
			--picToSliderDistance: var(--sp4x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp10x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp5x);

			//! Title to down line
			--titleToDownLine: var(--sp4x);

			//! Cursor normal size
			--cursorNormalSize: var(--sp4x);

			//! Cursor text size
			--cursorTextSize: var(--sp15x);

			//! Hover on clickable element
			--cursorHoverOnClickableElemSize: var(--sp7x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp14x);

			//! Slider arrow icon size
			--sliderArrowIconSize: var(--sp8x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp20x);

			//! Drag items distance
			--dragDistance: var(--sp5x);

			//! Related title distance
			--relatedTitleDistance: var(--sp5x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp4x);
			--animTitleWordDistanceSmall: var(--sp3x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		:root {
			--h1: 104px;
			--h2: 64px;
			--h3: 40px;
			--h4: 32px;
			--h5: 24px;
			--h6: 20px;
			--p: 18px;
			--pS: 16px;

			//! Container padding
			--containerPad: var(--sp8x);

			//! Sections distance
			--sectionsDistance: var(--sp11x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp16x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp6x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp6x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp4x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp4x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp4x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp6x);

			//! Pic to slider
			--picToSliderDistance: var(--sp3x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp9x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp4x);

			//! Title to down line
			--titleToDownLine: var(--sp3x);

			//! Cursor normal size
			--cursorNormalSize: var(--sp3x);

			//! Cursor text size
			--cursorTextSize: var(--sp13x);

			//! Hover on clickable element
			--cursorHoverOnClickableElemSize: var(--sp6x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp12x);

			//! Slider arrow icon size
			--sliderArrowIconSize: var(--sp6x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp15x);

			//! Drag items distance
			--dragDistance: var(--sp4x);

			//! Related title distance
			--relatedTitleDistance: var(--sp5x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp3x);
			--animTitleWordDistanceSmall: var(--sp1-5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		:root {
			--h1: 104px;
			--h2: 60px;
			--h3: 40px;
			--h4: 32px;
			--h5: 24px;
			--h6: 20px;
			--p: 18px;
			--pS: 16px;

			//! Container padding
			--containerPad: var(--sp7x);

			//! Sections distance
			--sectionsDistance: var(--sp11x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp16x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp6x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp6x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp4x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp4x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp4x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp6x);

			//! Pic to slider
			--picToSliderDistance: var(--sp3x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp9x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp4x);

			//! Title to down line
			--titleToDownLine: var(--sp3x);

			//! Cursor normal size
			--cursorNormalSize: var(--sp3x);

			//! Cursor text size
			--cursorTextSize: var(--sp11x);

			//! Hover on clickable element
			--cursorHoverOnClickableElemSize: var(--sp6x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp12x);

			//! Slider arrow icon size
			--sliderArrowIconSize: var(--sp6x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp15x);

			//! Drag items distance
			--dragDistance: var(--sp4x);

			//! Related title distance
			--relatedTitleDistance: var(--sp5x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp2x);
			--animTitleWordDistanceSmall: var(--sp2x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		:root {
			--h1: 80px;
			--h2: 52px;
			--h3: 32px;
			--h4: 24px;
			--h5: 20px;
			--h6: 18px;
			--p: 16px;
			--pS: 14px;

			//! Container padding
			--containerPad: var(--sp6x);

			//! Sections distance
			--sectionsDistance: var(--sp9x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp12x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp5x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp5x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp3x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp3x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp4x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp6x);

			//! Pic to slider
			--picToSliderDistance: var(--sp3x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp7x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp4x);

			//! Title to down line
			--titleToDownLine: var(--sp3x);

			//! Cursor normal size
			--cursorNormalSize: var(--sp3x);

			//! Cursor text size
			--cursorTextSize: var(--sp11x);

			//! Hover on clickable element
			--cursorHoverOnClickableElemSize: var(--sp6x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp11x);

			//! Slider arrow icon size
			--sliderArrowIconSize: var(--sp4x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp13x);

			//! Drag items distance
			--dragDistance: var(--sp3x);

			//! Related title distance
			--relatedTitleDistance: var(--sp2x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp2-5x);
			--animTitleWordDistanceSmall: var(--sp2x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		:root {
			--h1: 64px;
			--h2: 44px;
			--h3: 28px;
			--h4: 20px;
			--h5: 18px;
			--h6: 16px;
			--p: 14px;
			--pS: 14px;

			//! Container padding
			--containerPad: var(--sp4x);

			//! Sections distance
			--sectionsDistance: var(--sp6x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp9x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp4x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp4x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp3x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp3x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp3x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp5x);

			//! Pic to slider
			--picToSliderDistance: var(--sp2x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp5x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp3x);

			//! Title to down line
			--titleToDownLine: var(--sp2x);

			//! Cursor normal size
			--cursorNormalSize: var(--sp3x);

			//! Cursor text size
			--cursorTextSize: var(--sp11x);

			//! Hover on clickable element
			--cursorHoverOnClickableElemSize: var(--sp6x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp9x);

			//! Slider arrow icon size
			--sliderArrowIconSize: var(--sp4x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp9x);

			//! Drag items distance
			--dragDistance: var(--sp3x);

			//! Related title distance
			--relatedTitleDistance: var(--sp2x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp2x);
			--animTitleWordDistanceSmall: var(--sp1-5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		:root {
			--h1: 60px;
			--h2: 40px;
			--h3: 28px;
			--h4: 20px;
			--h5: 18px;
			--h6: 16px;
			--p: 14px;
			--pS: 14px;

			//! Container padding
			--containerPad: var(--sp4x);

			//! Sections distance
			--sectionsDistance: var(--sp6x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp9x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp4x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp4x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp3x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp3x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp3x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp5x);

			//! Pic to slider
			--picToSliderDistance: var(--sp2x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp5x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp3x);

			//! Title to down line
			--titleToDownLine: var(--sp2x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp9x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp9x);

			//! Drag items distance
			--dragDistance: var(--sp3x);

			//! Related title distance
			--relatedTitleDistance: var(--sp2x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp2x);
			--animTitleWordDistanceSmall: var(--sp2x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		:root {
			--h1: 48px;
			--h2: 32px;
			--h3: 20px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p: 14px;
			--pS: 14px;

			//! Container padding
			--containerPad: var(--sp3x);

			//! Sections distance
			--sectionsDistance: var(--sp5x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp8x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp4x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp6x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp2x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp1x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp3x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp4x);

			//! Pic to slider
			--picToSliderDistance: var(--sp2x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp4x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp2x);

			//! Title to down line
			--titleToDownLine: var(--sp1x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp4x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp8x);

			//! Drag items distance
			--dragDistance: var(--sp2x);

			//! Related title distance
			--relatedTitleDistance: var(--sp2x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp2x);
			--animTitleWordDistanceSmall: var(--sp1-5x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeS}) {
		:root {
			--h1: 46px;
			--h2: 26px;
			--h3: 20px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p: 14px;
			--pS: 14px;

			//! Container padding
			--containerPad: var(--sp3x);

			//! Sections distance
			--sectionsDistance: var(--sp5x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp8x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp4x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp6x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp2x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp1x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp3x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp4x);

			//! Pic to slider
			--picToSliderDistance: var(--sp2x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp4x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp2x);

			//! Title to down line
			--titleToDownLine: var(--sp1x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp4x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp8x);

			//! Drag items distance
			--dragDistance: var(--sp2x);

			//! Related title distance
			--relatedTitleDistance: var(--sp2x);

			//! Animated title word distances
			--animTitleWordDistance: var(--sp1-5x);
			--animTitleWordDistanceSmall: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeSMin}) {
		:root {
			--h1: 40px;
			--h2: 28px;
			--h3: 14px;
			--h4: 14px;
			--h5: 16px; /** //! For Input Size */
			--h6: 12px;
			--p: 12px;
			--pS: 12px;

			//! Container padding
			--containerPad: var(--sp3x);

			//! Sections distance
			--sectionsDistance: var(--sp5x);

			//! Section to text section / section to footer
			--secToTextSecToFooterDistance: var(--sp8x);

			//! Header to title / text to gallery / text to logo / pic to pic (individual)
			--bannerDistance: var(--sp4x);

			//! Pic to pic (home)
			--picToPicDistance: var(--sp6x);

			//! Pic to pic in gallery
			--picToPicGalleryDistance: var(--sp2x);

			//! Pic to pic in slider
			--picToPicSliderDistance: var(--sp1x);

			//! Pic to description / quote to text
			--picToDescQuoteToTextDistance: var(--sp3x);

			//! Title to text
			--titleToTextDistance: var(--sp2x);

			//! Content to small button
			--contentToSmallButtonDistance: var(--sp4x);

			//! Pic to slider
			--picToSliderDistance: var(--sp2x);

			//! Between tabs / between books
			--betweenTabsAndBooksDistance: var(--sp4x);

			//! Up line to title
			--upLineToTitleDistance: var(--sp2x);

			//! Title to down line
			--titleToDownLine: var(--sp1x);

			//! Hover video icon size
			--hoverVideoIconSize: var(--sp4x);

			//! Contact distance to footer
			--contactDistanceToFooter: var(--sp8x);

			//! Drag items distance
			--dragDistance: var(--sp2x);

			//! Related title distance
			--relatedTitleDistance: var(--sp2x);
		}
	}
`}`;

export default Variables;
