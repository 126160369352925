import { Component, createContext } from 'react';

//* HOCs
import { withRouter } from 'next/router';

//* Helpers
import { ThemeProvider } from 'styled-components';
import themes from 'styles/theme';
import HelperClass from 'styles/helperClass';
import Variables from 'styles/variables';
import Typography from 'styles/typography';
import RTL from 'styles/rtl';

const UIContext = createContext(null);

class UIProvider extends Component {
	//! States
	state = {
		winWidth: 1920,
		winHeight: 1080,
		screenSizes: {},
		preloader: true,
		popupIsOpen: false,
		isBurgerMenuOpen: false,
		isLightTheme: true,
	};

	popups = ['login', 'register', 'forgot-password'];

	//! Methods
	methods = {
		togglePopup: this.togglePopup.bind(this),
		disableDomScroll: this.disableDomScroll.bind(this),
		enableDomScroll: this.enableDomScroll.bind(this),
		toggleBurgerMenu: this.toggleBurgerMenu.bind(this),
		closeBurgerMenu: this.closeBurgerMenu.bind(this),
		disablePreloader: this.disablePreloader.bind(this),
		changeTheme: this.changeTheme.bind(this),
	};

	changeTheme(isLight) {
		this.setState({
			isLightTheme: isLight,
		});
	}

	//! Screen Resize
	resizeTimeout;
	screenResize = () => {
		this.resizeTimeout && clearTimeout(this.resizeTimeout);

		this.resizeTimeout = setTimeout(() => {
			this.setState({
				winWidth: window.innerWidth,
				winHeight: window.innerHeight,
			});
		}, 300);
	};

	//! Get Media Screen Sizes from Css Variables
	getScreenSizes() {
		const root = getComputedStyle(document.documentElement);

		const screenSizes = {
			desktopSizeXL: parseInt(root.getPropertyValue('--DesktopSizeXL')),
			desktopSizeL: parseInt(root.getPropertyValue('--DesktopSizeL')),
			desktopSizeM: parseInt(root.getPropertyValue('--DesktopSizeM')),
			desktopSizeS: parseInt(root.getPropertyValue('--DesktopSizeS')),
			desktopSizeXS: parseInt(root.getPropertyValue('--DesktopSizeXS')),
			tabletSize: parseInt(root.getPropertyValue('--TabletSize')),
			tabletSizeS: parseInt(root.getPropertyValue('--TabletSizeS')),
			mobileSize: parseInt(root.getPropertyValue('--MobileSize')),
		};

		this.setState({ screenSizes });
	}

	disablePreloader() {
		this.setState({ preloader: false });
	}

	togglePopup() {
		this.setState({
			popupIsOpen: !this.state.popupIsOpen,
		});
	}

	toggleBurgerMenu() {
		this.setState({ isBurgerMenuOpen: !this.state.isBurgerMenuOpen });
	}

	closeBurgerMenu() {
		this.state.isBurgerMenuOpen && this.setState({ isBurgerMenuOpen: false });
	}

	//! Disable DOM Scroll
	disableDomScroll() {
		// document.querySelector('html').classList.add('hide-scroll')

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		// if any scroll is attempted, set this to the previous value
		window.onscroll = function (e) {
			e.preventDefault();
			window.scrollTo(scrollLeft, scrollTop);
		};
	}

	//! Enable DOM Scroll
	enableDomScroll() {
		// document.querySelector('html').classList.remove('hide-scroll');

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		window.onscroll = function () {};
		window.scrollTo(scrollLeft, scrollTop);
	}

	//! Component Did Mount
	componentDidMount() {
		this.setState({
			...this.state,
		});

		window.addEventListener('resize', this.screenResize);
		this.getScreenSizes();
		this.screenResize();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.router.route !== this.props.router.route && this.props.router.route !== 'works/[slug]') {
			this.setState((prev) => {
				return {
					...prev,
					isLightTheme: true,
				};
			});
		}
	}

	//! Component Will Unmount
	componentWillUnmount() {
		window.addEventListener('resize', this.screenResize);
		document.removeEventListener('keydown', this.escKeydown, false);
	}

	render() {
		return (
			<UIContext.Provider value={{ ...this.state, ...this.methods }}>
				<ThemeProvider theme={themes(this.state.isLightTheme)}>
					<Variables />
					<Typography isBurgerMenuOpen={this.state.isBurgerMenuOpen} />
					<HelperClass />
					<RTL />
					{this.props.children}
				</ThemeProvider>
			</UIContext.Provider>
		);
	}
}

export default withRouter(UIProvider);
export const UIConsumer = UIContext.Consumer;
