import { gsap } from 'gsap'

export const transformByYPublicVersion = (item, stagger, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        transform: 'translateY(50%)',
    })

    gsap.to(item, {
        transform: 'translateY(0%)',
        stagger: stagger,
        delay: delay,
        duration: duration,
    })
}

export const opacity = (item, stagger, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        opacity: 0,
    })

    gsap.to(item, {
        opacity: 1,
        transitionTimingFunction: 'ease-out',
        stagger: stagger,
        delay: delay,
        duration: duration,
    })
}

export const transformByYWorksVersion = (item, stagger, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        y: '100%',
        opacity: 0,
    })

    gsap.to(item, {
        y: 0,
        stagger: stagger,
        delay: delay,
        duration: duration,
    })

    gsap.to(item, {
        stagger: stagger,
        delay: delay * 2,
        duration: duration - delay,
        opacity: 1,
    })
}

export const line = (item, stagger, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        width: 0,
        opacity: 0,
    })

    gsap.to(item, {
        width: '100%',
        opacity: 1,
        stagger: stagger,
        delay: delay,
        duration: duration,
    })
}

export const scale = (item, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        scale: 0,
    })

    gsap.to(item, {
        scale: 1,
        delay: delay,
        duration: duration,
    })
}

export const transformByY = (item, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        transform: 'translateY(50%)',
        opacity: 0,
    })

    gsap.to(item, {
        transform: 'translateY(0%)',
        delay: delay,
        duration: duration,
        opacity: 1,
    })
}

export const fakeBack = (item, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        display: 'block',
    })

    gsap.to(item, {
        display: 'none',
        delay: delay,
        duration: duration,
    })
}

export const slideAnimByTransformY = (item, stagger, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        transform: 'translateY(-40%)',
    })

    gsap.to(item, {
        transform: 'translateY(0%)',
        stagger: stagger,
        delay: delay,
        duration: duration,
    })
}

export const transformByYSlideVersion = (item, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, {
        transform: 'translateY(100%)',
    })

    gsap.to(item, {
        transform: 'translateY(0%)',
        delay: delay,
        duration: duration,
        opacity: 1,
    })
}

export const circleClipPathAnim = (item, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.set(item, { clipPath: 'circle(0% at 50% 50%)', delay: 0, duration: 0 })

    gsap.to(item, { clipPath: 'circle(100% at 50% 50%)', delay: delay, duration: duration })
}

export const slideByY = (arr, stagger, delay, duration) => {
    gsap.killTweensOf(arr)

    gsap.set(arr, {
        y: '150%',
        opacity: 0,
    })

    gsap.to(arr, {
        y: 0,
        stagger: stagger,
        delay: delay,
        duration: duration,
        opacity: 1,
    })
}

export const opacityNotSet = (item, stagger, delay, duration) => {
    gsap.killTweensOf(item)

    gsap.to(item, {
        opacity: 1,
        stagger: stagger,
        delay: delay,
        duration: duration,
    })
}

export const textNewSlideByYAnim = (arr, stagger, delay, duration) => {
    gsap.set(arr, {
        y: '150%',
    })

    gsap.to(arr, {
        y: 0,
        stagger: stagger,
        delay: delay,
        duration: duration,
        opacity: 1,
    })
}
