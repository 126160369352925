import styled from 'styled-components'

const ContainerStyle = styled.section`
	/* --contMaxWidth: var(--DesktopSizeXL); */
	--contMarginV: unset; //! not yet decided
	--colPadding: unset; //! not yet decided

	width: 100%;
	margin: 0 auto;

	&:not(.full) {
		/* max-width: var(--contMaxWidth); */
		padding-left: var(--containerPad);
		padding-right: var(--containerPad);
	}

	&.first-section {
		margin-top: var(--contMarginV);
	}

	&.section {
		margin-bottom: var(--contMarginV);
	}

	&.full {
		padding-left: 0;
		padding-right: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeL}) {
		/* --contMaxWidth: var(--DesktopSizeL); */
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		/* --contMaxWidth: var(--DesktopSizeM); */
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		/* --contMaxWidth: var(--DesktopSizeS); */
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeXS}) {
		/* --contMaxWidth: var(--DesktopSizeXS); */
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		/* --contMaxWidth: var(--TabletSize); */
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSizeS}) {
		/* --contMaxWidth: var(--TabletSizeS); */
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		/* --contMaxWidth: 100%; */
	}
`
export default ContainerStyle
