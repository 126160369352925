import styled from 'styled-components'

const CursorStyle = styled.div`
	--darkColor: var(--blackRussian);
	--lightColor: var(--white);

	.cursor {
		pointer-events: none;
		position: fixed;
		z-index: 9999999;

		&.crHide {
			opacity: 0;
		}

		&.sliderReverseArrow,
		&.sliderArrow {
			transform: translate(-50%, -50%) scale(1);
			mix-blend-mode: difference;

			&:before {
				color: var(--lightColor);
				font-size: var(--sliderArrowIconSize);
			}
            &.dark {
                &:before {
                    color: var(--darkColor);
                }
            }
		}

		&.videoPlayIconAnim,
		&.videoPauseIconAnim {
			transform: translate(-50%, -50%) scale(1);
			mix-blend-mode: difference;

			&:before {
				color: var(--lightColor);
				font-size: var(--hoverVideoIconSize);
			}

			&.videoCursorClickAnim {
				animation: videoCursorAnim var(--trTimeS) ease-in-out forwards;
			}

			&.videoCursorClickReverseAnim {
				animation: videoCursorReverseAnim var(--trTimeS) ease-in-out forwards;
			}
            &.dark {
                &:before {
                    color: var(--darkColor);
                }
            }
		}

		&.default,
		&.linkWrap,
		&.cursorClickAnim,
		&.dragIntWrap {
            
			.cursorTextWrap {
				position: relative;
				width: var(--cursorNormalSize);
				height: var(--cursorNormalSize);
				border-radius: 50%;
				background-color: var(--lightColor);
				transform: translate(-50%, -50%) scale(1);
				transition: all var(--trTimeS) ease;

				.cursorText {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-right: -50%;
					transform: translate(-50%, -50%);
					font-size: var(--sp1x);
				}
			}

			&.linkWrap {
				.cursorTextWrap {
					background-color: var(--lightColor);
					transform: translate(-50%, -50%) scale(1.9);
				}
			}

			&.dragIntWrap {
				.cursorTextWrap {
					background-color: var(--darkColor);
					transform: translate(-50%, -50%) scale(3);
				}
			}

			&.mixed {
				mix-blend-mode: difference;
			}

			&.mixedNone {
				mix-blend-mode: none;
			}

			&.cursorClickAnim {
				.cursorTextWrap {
					animation: cursorAnim var(--trTimeS) ease-in-out;
				}
			}

            &.dark {
                .cursorTextWrap {
                    background-color: var(--darkColor);
                }
                &.dragIntWrap {
                    .cursorTextWrap {
                        background-color: var(--darkColor);
                    }
                }
                &.linkWrap {
				.cursorTextWrap {
					background-color: var(--darkColor);
				}
			}
            }

			//! Cursor click animations
			@keyframes cursorAnim {
				from {
					transform: translate(-50%, -50%) scale(1.2);
				}
				to {
					transform: translate(-50%, -50%) scale(1.9);
				}
			}

			@keyframes videoCursorAnim {
				0% {
					transform: translate(-50%, -50%) scale(0.8);
				}
				50% {
					transform: translate(-50%, -50%) scale(1.2);
				}
				100% {
					transform: translate(-50%, -50%) scale(1);
				}
			}

			@keyframes videoCursorReverseAnim {
				0% {
					transform: translate(-50%, -50%) scale(0.8);
				}
				50% {
					transform: translate(-50%, -50%) scale(1.2);
				}
				100% {
					transform: translate(-50%, -50%) scale(1);
				}
			}
		}

		.cursorTextWrap {
			width: var(--cursorNormalSize);
			height: var(--cursorNormalSize);
			border-radius: 50%;
			transform: translate(-50%, -50%) scale(1);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) {
		.cursorText {
			font-size: var(--sp0-75x) !important;
		}
	}
`
export default CursorStyle
