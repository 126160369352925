import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

//* HOC's
import withDataContext from 'context/consumerHOC/DataConsumer';
import withLanguageContext from 'context/consumerHOC/LanguageConsumer';

//* helpers
import config from 'helpers/_config';
import { opacity, fakeBack } from 'helpers/_gsapFunctions';

//* Components
import AnimationWrap from 'components/common/AnimationWrap';
import Container from 'components/common/Container';
import CustomLink from 'components/common/CustomLink';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';

//* Styles
import FooterStyle from './style';

const AppFooter = ({ global, selectedLang, translate }) => {
	//! Global Data
	const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang]);

	//! Router
	const router = useRouter();

	//! Refs
	const items = useRef([]);
	const emailRef = useRef();
	const hr_emailRef = useRef();
	const phoneRef = useRef();
	const footerMenuLeftItems = useRef([]);
	const footerMenuRightItems = useRef([]);
	const facebookRef = useRef();
	const behanceRef = useRef();
	const instagramRef = useRef();
	const pinterestRef = useRef();
	const linkedinRef = useRef();
	const fakeBackground = useRef();

	const routesArr = Object.values(config.routes);

	const [secObj, setSecObj] = useState();

	useEffect(() => {
		if (items?.current) {
			setSecObj([items.current]);
		}
	}, [items]);

	const startAnimeArr = useCallback((x) => {
		const arr = [emailRef.current, hr_emailRef.current, phoneRef.current, ...footerMenuLeftItems.current, ...footerMenuRightItems.current];

		const arr2 = [facebookRef.current, behanceRef.current, instagramRef.current, pinterestRef.current, linkedinRef.current];

		const filterArr = arr.filter((el) => typeof el !== 'undefined');
		const filterArr2 = arr2.filter((el) => typeof el !== 'undefined');

		if (x) {
			//! item, stagger, delay, duration
			opacity(filterArr, 0.05, 0.05, 1);

			//! item, stagger, delay, duration
			opacity(filterArr2, 0.05, 0.1, 0.9);

			//! item, delay, duration
			fakeBack(fakeBackground.current, 0.1, 0.1);
		}
	}, []);

	const footerItemsLeft = useMemo(() => {
		return routesArr.map(
			(route, index) =>
				route.menu &&
				index <= 4 && (
					<CustomLink
						ref={(el) => (footerMenuLeftItems.current[index] = el)}
						key={index}
						url={route.path}
						className={`footerMenuItem uppercase h5 gilroyMedium footerTextColor btnLineAnim opacityZero`}
						content={translate(route.name)}
					/>
				)
		);
	}, []);

	const footerItemsRight = useMemo(() => {
		return routesArr.map(
			(route, index) =>
				route.menu &&
				index > 4 && (
					<CustomLink
						ref={(el) => (footerMenuRightItems.current[index] = el)}
						key={index}
						url={route.path}
						className={`footerMenuItem uppercase h5 gilroyMedium footerTextColor btnLineAnim opacityZero`}
						content={translate(route.name)}
					/>
				)
		);
	}, []);

	const footerFakeBack = useMemo(() => {
		return (
			<div
				className={`fakeBackground ${router.asPath === '/contact/' ? 'whiteBack' : ''}`}
				ref={fakeBackground}
			/>
		);
	}, [router.asPath]);

	return (
		<FooterStyle className={`footer ${router.asPath === '/contact/' ? 'reverseBackgroundColor' : ''}`}>
			<AnimationWrap
				state={secObj}
				startAnimeArr={startAnimeArr}
				dependency={router.asPath}>
				<Container ref={items}>
					<div className={`firstSection`}>
						{footerFakeBack}
						<div className={`firstColumn`}>
							<div
								className={'footerEmailWrap opacityZero'}
								ref={emailRef}>
								<CustomLink
									rel={'noopener noreferrer'}
									url={`mailto:${globalData?.contact_details.email}`}
									className={'h3 gilroyMedium footerTextColor uppercase btnLineAnim'}
									content={globalData?.contact_details.email}
									ariaLabel={globalData?.contact_details.email}
								/>
							</div>

							<div
								className={'footerHrEmailWrap opacityZero'}
								ref={hr_emailRef}>
								<CustomLink
									rel={'noopener noreferrer'}
									url={`mailto:${globalData?.contact_details.second_email}`}
									className={'h3 gilroyMedium footerTextColor uppercase btnLineAnim'}
									content={globalData?.contact_details.second_email}
									ariaLabel={globalData?.contact_details.second_email}
								/>
							</div>

							<div
								className={'footerPhoneWrap opacityZero'}
								ref={phoneRef}>
								<CustomLink
									rel={'noopener noreferrer'}
									url={`tel:${globalData?.contact_details.phone}`}
									className={'h3 gilroyMedium footerTextColor uppercase btnLineAnim'}
									content={`+ ${globalData?.contact_details.phone}`}
									ariaLabel={`+ ${globalData?.contact_details.phone}`}
								/>
							</div>

							<div className='footerSocialIconsWrap'>
								{globalData?.socials.facebook && (
									<div
										className={`iconWrap opacityZero`}
										ref={facebookRef}>
										<CustomLink
											url={globalData?.socials.facebook}
											options={{
												rel: 'noopener noreferrer',
												target: '_blank',
											}}
											ariaLabel={globalData?.socials.facebook}>
											<Icon className='bb-facebook' />
										</CustomLink>
									</div>
								)}

								{globalData?.socials.behance && (
									<div
										className={`iconWrap opacityZero`}
										ref={behanceRef}>
										<CustomLink
											url={globalData?.socials.behance}
											options={{
												rel: 'noopener noreferrer',
												target: '_blank',
											}}
											ariaLabel={globalData?.socials.behance}>
											<Icon className='bb-behance' />
										</CustomLink>
									</div>
								)}

								{globalData?.socials.instagram && (
									<div
										className={`iconWrap opacityZero`}
										ref={instagramRef}>
										<CustomLink
											url={globalData?.socials.instagram}
											options={{
												rel: 'noopener noreferrer',
												target: '_blank',
											}}
											ariaLabel={globalData?.socials.instagram}>
											<Icon className='bb-instagram' />
										</CustomLink>
									</div>
								)}

								{globalData?.socials.pinterest && (
									<div
										className={`iconWrap opacityZero`}
										ref={pinterestRef}>
										<CustomLink
											url={globalData?.socials.pinterest}
											options={{
												rel: 'noopener noreferrer',
												target: '_blank',
											}}
											ariaLabel={globalData?.socials.pinterest}>
											<Icon className='bb-pinterest' />
										</CustomLink>
									</div>
								)}

								{globalData?.socials.linkedin && (
									<div
										className={`iconWrap opacityZero`}
										ref={linkedinRef}>
										<CustomLink
											url={globalData?.socials.linkedin}
											options={{
												rel: 'noopener noreferrer',
												target: '_blank',
											}}
											ariaLabel={globalData?.socials.linkedin}>
											<Icon className='bb-linkedin' />
										</CustomLink>
									</div>
								)}
							</div>
						</div>

						<div className={`secondColumn`}>
							<div className={`footerItemsLeft`}>{footerItemsLeft}</div>

							<div className={`footerItemsRight`}>{footerItemsRight}</div>
						</div>
					</div>

					<div className={`lastSection`}>
						<div className={`firstPart ${router.asPath !== '/contact/' ? 'opacity-0-5' : ''}`}>
							<Text className={`p uppercase gilroyMedium footerTextColor`}>© ALL RIGHTS RESERVED {new Date().getFullYear()}</Text>
						</div>

						<div className={`secondPart ${router.asPath !== '/contact/' ? 'opacity-0-5' : ''}`}>
							<Text
								className={`p uppercase gilroyMedium footerTextColor`}
								text={'by'}
							/>
							&nbsp;
							<CustomLink
								content={'CONCEPT STUDIO'}
								url={'https://conceptstudio.com/en/'}
								ariaLabel={'https://conceptstudio.com/en/'}
								className={`p uppercase gilroySemiBold footerTextColor`}
								options={{ rel: 'noopener noreferrer', target: '_blank' }}
							/>
						</div>
					</div>
				</Container>
			</AnimationWrap>
		</FooterStyle>
	);
};

export default withLanguageContext(withDataContext(AppFooter, ['global']), ['selectedLang', 'translate']);
